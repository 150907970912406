import styled from 'styled-components';
import { colors, media } from 'themes';
import { Container } from 'styled-bootstrap-grid';
import Button from 'components/shared/Button';

export const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  background: white;
`;

export const Box = styled.div`
  position: relative;
  width: 914px;
  height: 1530px;
  text-align: center;
  border-radius: 70px;
  background: linear-gradient(
    135deg,
    ${colors.caribbeanGreen},
    ${colors.marineBlue},
    ${colors.caribbeanGreen},
    ${colors.marineBlue},
    ${colors.caribbeanGreen},
    ${colors.marineBlue}
  );
  background-size: 800% 800%;
  animation: GradientAnimation 25s linear infinite;
  margin-bottom: 550px;

  ${media.md`
    height: auto;
    padding-bottom: 95px;
    margin-bottom: 112px;
    width: 660px;
  `}

  ${media.sm`
    width: 100%;
    max-width: 540px;
    padding: 0 15px 90px 15px;
    background: white;
    border-radius: 0;
    animation: none;
    margin-bottom: 0;
    text-align: left;
  `}

  ${media.xs`
    max-width: none;    
  `}
  
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 90px);
    height: calc(100% - 90px);
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 33px;

    ${media.sm`
      display: none;
    `};
  }
`;

export const Web = styled.img`
  position: absolute;
  top: 70px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);

  ${media.sm`
    display: none;
  `};
`;

export const Title = styled.h1`
  margin-top: 110px;
  font-size: 48px;
  line-height: 58px;
  font-weight: bold;

  ${media.sm`
    margin-top: 0;
    font-size: 32px;
    line-height: 36px;
    padding-right: 50px;
  `};
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
`;

export const Description = styled.h3`
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
  padding: 0 160px;
  margin-top: 40px;
  margin-bottom: 50px;

  ${media.sm`
    padding: 0;
    text-align: left;
  `}
`;

export const CTAButton = styled(Button)`
  transition: transform 500ms cubic-bezier(0.7, -2, 0.305, 2), box-shadow 500ms linear;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 15px -5px rgba(47, 98, 219, 0.5);

    ${media.sm`
      transform: none;
    `}
  }
`;

export const Products = styled(Container)`
  display: flex;
  justify-content: space-between;
  width: auto;
  margin-left: -110px;
  margin-right: -110px;
  margin-top: 210px;
  padding: 0;

  ${({ center }) => center && `
    justify-content: center;
  `} 

  ${media.lg`
    margin-left: -20px;
    margin-right: -20px;
  `}

  ${media.md`
    display: block;
    margin-left: -32px;
    margin-right: -32px;
    max-width: none;
    margin-top: 190px;

    ${({ center }) => center && `
      margin-top: 64px;
    `} 
  `}

  ${media.sm`
    margin: 10px 0 0 0;
  `};
`;

export const Card = styled.div`
  position: relative;
  width: 360px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px -10px rgba(110, 110, 110, 0.25);
  padding: 130px 32px 32px 32px;
  text-align: left;

  ${media.md`
    width: auto;
    padding-top: 48px;
    padding-bottom: 48px;
  `}
  
  ${media.sm`
    padding: 50px 0 0 0;
    box-shadow: none;
  `}

  & + & {
    margin-left: 30px;

    ${media.md`
      margin-left: 0;
      margin-top: 64px;
    `}

    ${media.sm`
      margin-top: 0;
    `}
  }
`;

export const ProductName = styled.h3`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;

  ${media.sm`
    margin-top: 30px;
  `}
`;

export const ProductDescription = styled.div`
  margin-top: 24px;
  color: #798291;
  font-size: 16px;
  line-height: 24px;

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: 22px;
      line-height: 32px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background: ${colors.caribbeanGreen};
        border-radius: 50%;
      }
    }
  }
`;

export const ProductIcon = styled.img`
  position: absolute;
  top: -160px;
  left: 10px;
  max-width: 77%;

  ${media.md`
    left: auto;
    right: 90px;
    max-width: 35%;
  `}

  ${media.sm`
    position: relative;
    top: auto;
    right: auto;
    left: auto;
  `}
`;
