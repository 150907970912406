import React, { Component } from 'react';

import Item from './components/Item';
import items from './items';
import { Wrapper, Title, LineWrapper, Line, Items, Circles, MobileLine } from './styled';

class Timeline extends Component {
  state = {
    lineHeight: 0,
  };

  componentDidMount() {
    this.doc = document.documentElement;
    this.lineOffset = this.line.getBoundingClientRect().top;
    this.viewportHeight = window.innerHeight;

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const top = window.pageYOffset || this.doc.scrollTop;
    const lineHeight = top - (this.lineOffset - this.viewportHeight / 2);

    this.setState({
      lineHeight
    });
  };

  calculateLineHeight = () => {
    const { lineHeight } = this.state;

    if (lineHeight < 0) {
      return 0;
    } else if (lineHeight > 1290) {
      return 1290;
    }

    return lineHeight;
  }

  render() {
    return (
      <Wrapper>
        <Title>Discover How We Deliver Your Products</Title>

        <Circles />

        <LineWrapper>
          <Line width="12" height="1290" version="1.1" xmlns="http://www.w3.org/2000/svg" ref={el => (this.line = el)}>
            <line x1="6" y1="10" x2="6" y2="1285" />
            
            <circle cx="6" cy="6" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
            <circle cx="6" cy="324" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
            <circle cx="6" cy="543" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
            <circle cx="6" cy="788" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
            <circle cx="6" cy="960" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
            <circle cx="6" cy="1132" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
            <circle cx="6" cy="1280" r="5" fill="#FFFFFF" stroke="#E7E7E7" />
          </Line>
        </LineWrapper>

        <LineWrapper>
          <Line
            green
            width="19"
            height={this.calculateLineHeight()}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            ref={el => (this.line = el)}
          >
            <line x1="10" y1="2" x2="10" y2="1285" />

            <circle cx="10" cy="6" r="6" fill="#0ED29F" />

            <circle cx="10" cy="324" r="7" fill="#FFFFFF" stroke="#0ED29F" strokeWidth="3" />
            <circle cx="10" cy="324" r="2.5" fill="#0ED29F" />

            <circle cx="10" cy="543" r="7" fill="#FFFFFF" stroke="#0ED29F" strokeWidth="3" />
            <circle cx="10" cy="543" r="2.5" fill="#0ED29F" />

            <circle cx="10" cy="788" r="7" fill="#FFFFFF" stroke="#0ED29F" strokeWidth="3" />
            <circle cx="10" cy="788" r="2.5" fill="#0ED29F" />

            <circle cx="10" cy="960" r="7" fill="#FFFFFF" stroke="#0ED29F" strokeWidth="3" />
            <circle cx="10" cy="960" r="2.5" fill="#0ED29F" />

            <circle cx="10" cy="1132" r="7" fill="#FFFFFF" stroke="#0ED29F" strokeWidth="3" />
            <circle cx="10" cy="1132" r="2.5" fill="#0ED29F" />

            <circle cx="10" cy="1280" r="7" fill="#FFFFFF" stroke="#0ED29F" strokeWidth="3" />
            <circle cx="10" cy="1280" r="2.5" fill="#0ED29F" />
          </Line>
        </LineWrapper>

        <MobileLine />

        <Items>
          {items.map((item, i) => (
            <Item key={item.number} left={i % 2} {...item} />
          ))}
        </Items>
      </Wrapper>
    );
  }
}

export default Timeline;
