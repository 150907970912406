import React from 'react';
import { Wrapper, Number, Title, Description } from './styled';

const Item = ({ number, title, description, left }) => (
  <Wrapper left={left}>
    <Number>{number}</Number>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
);

export default Item;
