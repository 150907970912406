import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import FileQuote from 'images/quote.svg';
import FileMteLogo from 'images/mte-logo-quote.svg';
import FileLuxsferyLogo from 'images/luxsfery-logo-quote.svg';
import FileJitlLogo from 'images/jitl-logo-quote.svg';

import Container from 'components/shared/Container';

import {
  Wrapper,
  Title,
  Referal,
  Brand,
  Content,
  Company,
  Person,
  Heading,
  Description,
  QuoteIcon,
  Logo,
  LogoWrapper
} from './styled';

const Referals = () => (
  <Wrapper>
    <Container>
      <Title>What clients say about our work</Title>

      <Row>
        <Col lg={4}>
          <Referal>
            <Brand>
              <LogoWrapper>
                <Logo src={FileMteLogo} />
              </LogoWrapper>
              <Company>Metro Tech Erectors</Company>
              <Person>Paul Hogendorf, President</Person>
            </Brand>
            <QuoteIcon src={FileQuote} />
            <Content>
              <Heading>
                We have found <span>Innovatica</span> to be highly reliable...
              </Heading>
              <Description>
                ...in terms of consultancy, management and development capacities.
                <br />
                <br />
                They have provided exceptional design and functionalities for our ongoing project. I would recommend
                them for any digital challenge with no hesitations.
              </Description>
            </Content>
          </Referal>
        </Col>
        <Col lg={4}>
          <Referal>
            <Brand>
              <LogoWrapper>
                <Logo src={FileLuxsferyLogo} />
              </LogoWrapper>
              <Company>Luxsfery Men’s Fashion Club</Company>
              <Person>Tom Nowicki, Co-Founder</Person>
            </Brand>
            <QuoteIcon src={FileQuote} />
            <Content>
              <Heading>
                We are more than happy about the cooperation with <span>Innovatica</span>...
              </Heading>
              <Description>
                ...which appeared to be super agile and really cost effective.
                <br />
                <br />
                They helped us select the right technology stack, start development process and let us finalize fully
                tested MVP within four months.
              </Description>
            </Content>
          </Referal>
        </Col>
        <Col lg={4}>
          <Referal>
            <Brand>
              <LogoWrapper>
                <Logo src={FileJitlLogo} />
              </LogoWrapper>
              <Company>Jump Into The Light</Company>
              <Person>Mike Skalski, Chief Creative Director</Person>
            </Brand>
            <QuoteIcon src={FileQuote} />
            <Content>
              <Heading>
                We have an ongoing relationship with <span>Innovatica</span> for a longer period of time now.
              </Heading>
              <Description>
                We constantly need software development resources and strong expertise in VR, augmented reality
                development and IT operations, that they manage very well to provide.
                <br />
                <br />
                We can definitely recommend Innovatica as trusted CTO and partner in the technology and IT consulting.
              </Description>
            </Content>
          </Referal>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Referals;
