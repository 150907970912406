import { Container as StyledContainer } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Container = styled(StyledContainer)`
  padding: 0;

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`;

export default Container;
