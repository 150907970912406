import React from 'react';
import { scroller } from 'react-scroll';
import ImageWeb from 'images/web_animated.svg';
import ImageWebDevelopment from 'images/web_development.svg';
import ImageProductDevelopment from 'images/product_development.svg';
import ImageMobileDevelopment from 'images/mobile_development.svg';
import ImageDevops from 'images/devops.svg';
import ImageCloudServices from 'images/cloud_services.svg';
import {
  Wrapper,
  Box,
  Title,
  Description,
  Web,
  Content,
  CTAButton,
  Products,
  Card,
  ProductName,
  ProductIcon,
  ProductDescription
} from './styled';

const Header = () => (
  <Wrapper>
    <Box>
      <Web src={ImageWeb} />

      <Content>
        <Title>
          We design &amp; develop.
          <br />
          We innovate.
        </Title>

        <Description>
          We are the team of executive IT experts dedicated to solve problems of technology companies.
        </Description>

        <CTAButton
          type="primary"
          onClick={() =>
            scroller.scrollTo('contact', {
              duration: 800,
              delay: 0,
              smooth: 'easeInOutQuart'
            })
          }
        >
          Get Estimate
        </CTAButton>

        <Products>
          <Card>
            <ProductIcon src={ImageWebDevelopment} alt="Web development" />
            <ProductName>Web Development</ProductName>
            <ProductDescription>
              Our keystone competency at Innovatica is crafting seamless, scalable and secure web applications, tailored
              to your business needs, regardless of the actual growth stage. We always strive for the highest quality
              and excellent backend as well as frontend experience.
            </ProductDescription>

            <ProductDescription>
              <ul>
                <li>Ruby on Rails Development</li>
                <li>Angular, React &amp; Vue Development</li>
                <li>Node.js Development</li>
              </ul>
            </ProductDescription>
          </Card>

          <Card>
            <ProductIcon src={ImageMobileDevelopment} alt="Mobile development" />
            <ProductName>Mobile Development</ProductName>
            <ProductDescription>
              At Innovatica we create world-class, incredible, native apps that run on major operating systems such as
              iOS and Android. We promote user-centered approach, perfecting every detail to develop user-friendly, high
              usable mobile apps.
            </ProductDescription>

            <ProductDescription>
              <ul>
                <li>iOS App Development</li>
                <li>Android App Development</li>
                <li>React Native Development</li>
              </ul>
            </ProductDescription>
          </Card>

          <Card>
            <ProductIcon src={ImageProductDevelopment} alt="Product development" />
            <ProductName>UX/Product Design</ProductName>
            <ProductDescription>
              We believe that real user engagement takes place at the intersection of technology and design. In order to
              convert your ideas into comprehensive look and feel, our UX/UI Designers always strive to thoroughly grasp
              your business goals and user behavior.
            </ProductDescription>

            <ProductDescription>
              <ul>
                <li>Design Sprint</li>
                <li>User Interface Design</li>
                <li>User Experience Services</li>
                <li>Brand Development</li>
              </ul>
            </ProductDescription>
          </Card>
        </Products>

        <Products center>
          <Card>
            <ProductIcon src={ImageDevops} alt="Product development" />
            <ProductName>DevOps</ProductName>
            <ProductDescription>
              We provide customers with DevOps services to build highly comprehensive and reliable infrastructure,
              support their IT transformation when moving workloads to the cloud encompassing iterative and incremental
              development, workload management on demand and lightweight architecture.
            </ProductDescription>

            <ProductDescription>
              <ul>
                <li>CD / CI processes</li>
                <li>Infrastructure as a Code</li>
                <li>Security, testing and maintenance</li>
              </ul>
            </ProductDescription>
          </Card>

          <Card>
            <ProductIcon src={ImageCloudServices} alt="Product development" />
            <ProductName>Cloud services</ProductName>
            <ProductDescription>
              We are working with the cloud and deliver serverless solutions. We have vast experience in cloud
              migrations, cloud native solutions and high traffic production systems.
            </ProductDescription>

            <ProductDescription>
              <ul>
                <li>Multilevel Cloud migration</li>
                <li>Microserices</li>
                <li>Serverless infrastructure</li>
                <li>Cloud DevOps &amp; SysOps</li>
              </ul>
            </ProductDescription>
          </Card>
        </Products>
      </Content>
    </Box>
  </Wrapper>
);

export default Header;
