import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.div`
  position: relative;
  max-width: calc(50% - 75px);
  margin-left: auto;

  ${({ left }) => left && `
    margin-left: 30px;
    text-align: right;
  `}

  ${media.md`
    max-width: calc(50% - 75px);
    margin-right: 30px;
  `}

  ${media.sm`
    max-width: 100%;
    margin: 100px 16px 0 16px;;
    text-align: left;
    box-shadow: 0 10px 20px -10px rgba(110,110,110,0.25);
    background: #FFFFFF;
    padding: 22px 16px 18px 16px;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 17px;
      height: 17px;
      background-color: #FFFFFF;
      border: 3px solid #0ED29F;
      border-radius: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      top: -34px;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
      background-color: #0ED29F;
      border-radius: 50%;
    }

    &:first-child {
      &:before {
        top: -60px;
      }

      &:after {
        top: -54px;
      }
    }
  `}
`;

export const Number = styled.div`
  color: #c1c7d0;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
`;

export const Title = styled.div`
  color: #2b2b2b;
  font-size: 18px;
  font-weight: bold;
  line-height: 16px;
  margin: 4px 0 16px;
`;

export const Description = styled.div`
  color: #798291;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
