import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.div`
  position: relative;
  width: 765px;
  margin: 0 auto;
  background: #ffffff;

  ${media.sm`
    width: 100%;
  `}
`;

export const Title = styled.h3`
  position: relative;
  z-index: 1;
  color: #2b2b2b;
  font-size: 32px;
  line-height: 39px;
  font-weight: bold;
  text-align: center;

  ${media.sm`
    font-size: 24px;
    line-height: 29px;
    padding: 0 50px;
  `}
`;

export const LineWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 200px;

  ${media.sm`
    display: none;
  `}
`;

export const Line = styled.svg`
  ${({ green }) => green && `
    position: relative;
    z-index: 1;
    backface-visibility: hidden;
    transform: translateX(-1px);
  `}

  line {
    stroke: #E7E7E7;
    stroke-width: 1;
    stroke-dasharray: 10, 10;

    ${({ green }) => green && `
      stroke: #0ED29F;
      stroke-width: 4px;
      stroke-dasharray: none;
    `}
  }
`;

export const Items = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 60px;
  margin-bottom: 150px;
`

export const Circles = styled.div`
  position: absolute;
  top: -53px;
  left: 223px;
  width: 318px;
  height: 318px;
  border: 1px solid #F2F2F2;
  border-radius: 50%;

  ${media.sm`
    left: 50%;
    transform: translateX(-50%);
  `}

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border: 1px solid #F2F2F2;
    border-radius: 50%;
  }
`

export const MobileLine = styled.div`
  position: absolute;
  top: 107px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  width: 3px;
  background-color: #0ED29F;

  ${media.sm`
    display: block;
  `}
`;
