import styled from 'styled-components';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { colors, media } from 'themes';
import Button from 'components/shared/Button';

export const Wrapper = styled.section`
  padding: 130px 0;
  background: ${colors.marineBlue};
  color: white;

  ${media.sm`
    padding: 64px 0 80px;
  `};
`;

export const Title = styled.h3`
  font-size: 48px;
  line-height: 58px;
  font-weight: bold;
  text-align: center;

  ${media.sm`
    font-size: 32px;
    line-height: 36px;
    text-align: left;
  `};
`;

const BaseInputStyles = `
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: 0;
  color: #939393;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 23px;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: #ccc;
  }
`;

export const Input = styled(Field)`
  ${BaseInputStyles};
`;

export const Textarea = styled(TextareaAutosize)`
  ${BaseInputStyles}
  
  resize: none;
  min-height: 90px;
  margin-top: -16px;
`;

export const Form = styled.form`
  width: 464px;
  margin: 55px auto 0 auto;
  text-align: center;

  ${media.sm`
    width: 100%;
    text-align: left;
  `};
`;

export const FormGroup = styled.div`
  margin-bottom: 24px;
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
  user-select: none;

  ${({ space }) =>
    space &&
    ` 
    margin-bottom: 16px;
  `};
`;

export const SendButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-top: 55px;
  transition: transform 500ms cubic-bezier(0.7, -2, 0.305, 2), box-shadow 500ms linear, width 200ms;

  ${media.sm`
    margin-top: 24px;
  `}

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 15px -5px #05297e;

    ${media.sm`
      transform: none;
    `}
  }

  ${({ disabled }) => disabled && `
    background: #B0B0B0;
    
    &:hover {
      transform: none;
    }
  `}

  ${({ sent }) => sent && `
    pointer-events: none;

    &:hover {
      transform: none;
      box-shadow: auto;
    }
  `}
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
`;

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: white;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 16px;

  ${({ checked }) =>
    checked &&
    `
    background: ${colors.caribbeanGreen};
  `};
`;

export const ErrorWrapper = styled.div`
  color: red;
  margin-top: 10px;
  font-weight: bold;
  text-align: left;
`;

export const Upload = styled.div`
  margin-top: -16px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  color: ${colors.caribbeanGreen};

  .dropzone {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

    img {
      margin-right: 16px;
    }
  }
`;

export const Loader = styled.img`
  position: relative;
  top: 2px;
  height: 16px;
  margin-left: 20px;
`;
