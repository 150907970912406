import React from 'react';
import * as Buttons from './styled';

const Button = ({ type, children, ...props }) => {
  const buttonName = `${type}Button`;
  const Component = Buttons[buttonName];

  return <Component {...props}>{children}</Component>;
};

export default Button;
