import React, { Component, Fragment } from 'react';
import Container from 'styled-bootstrap-grid/dist/Container';
import { Formik, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';
import { Element } from 'react-scroll';
import axios from 'axios';
import Typed from 'react-typed';

import ImageCheck from 'images/check.svg';
import ImageUpload from 'images/upload.svg';
import ImageLoader from 'images/loader.svg';
import {
  Wrapper,
  Title,
  Input,
  Form,
  FormGroup,
  Label,
  SendButton,
  Checkbox,
  Toggle,
  ErrorWrapper,
  Textarea,
  Upload,
  Loader
} from './styled';

class ContactForm extends Component {
  state = {
    descriptionField: false,
    ndaField: false,
    nda: null,
    sent: false
  };

  toggleField = fieldName => {
    this.setState(previousState => ({
      [fieldName]: !previousState[fieldName]
    }));
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleFileUpload = ([nda]) => {
    this.setState({
      nda
    });
  };

  preparePayload = async ({ values: { email, description } }) => {
    const payload = {};

    
    if (this.state.nda) {
      const base64Attachment = await this.getBase64(this.state.nda);

      payload.attachments = [
        {
          filename: this.state.nda.name,
          content: base64Attachment.split('base64,')[1],
          encoding: 'base64'
        },
      ];
    }

    payload.html = `
        <h3>New estimate enquiry</h3>

        <p>
          <strong>Email: </strong>
          ${email}
        </p>
        <p>
          <strong>Description:</strong>
          ${description ? description : 'Not provided'}
        </p>
      `;

    return payload;
  };

  handleFormSubmit = async (values, { setSubmitting }) => {
    const payload = await this.preparePayload({ values });

    try {
      await axios.post('https://9lsmko5w60.execute-api.us-east-2.amazonaws.com/prod/', payload);

      this.setState({
        sent: true
      });
    } catch (error) {
      console.log('error m8', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    return (
      <Fragment>
        <Element id="contact" name="contact" />
        <Wrapper>
          <Container>
            <Title>
            <Typed 
              strings={[
                'What is your digital challenge?',
                'Do you need an app? ',
                'Do you need a website?',
                'Let’s work together!',
              ]}
              typeSpeed={40}
              backSpeed={50} 
              loop />
            </Title>

            <Formik
              validateOnBlur={false}
              initialValues={{ email: '', description: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'E-mail address is required';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = 'Invalid e-mail address';
                }
                return errors;
              }}
              onSubmit={this.handleFormSubmit}
            >
              {({ errors, touched, handleSubmit, isSubmitting, handleChange, handleBlur, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label htmlFor="email" space>
                      Your e-mail address:
                    </Label>
                    <Input id="email" type="email" name="email" placeholder="E-mail address..." />
                    <ErrorMessage name="email" component={ErrorWrapper} />
                  </FormGroup>

                  <FormGroup>
                    <Checkbox>
                      <Toggle
                        checked={this.state.descriptionField}
                        onClick={() => this.toggleField('descriptionField')}
                      >
                        <img src={ImageCheck} alt="Checked" />
                      </Toggle>
                      <Label onClick={() => this.toggleField('descriptionField')}>I want to describe my product</Label>
                    </Checkbox>
                  </FormGroup>

                  {this.state.descriptionField && (
                    <FormGroup>
                      <Textarea
                        name="description"
                        placeholder="You can pitch your idea here..."
                        component="textarea"
                        rows={3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                    </FormGroup>
                  )}

                  <FormGroup>
                    <Checkbox>
                      <Toggle checked={this.state.ndaField} onClick={() => this.toggleField('ndaField')}>
                        <img src={ImageCheck} alt="Checked" />
                      </Toggle>
                      <Label onClick={() => this.toggleField('ndaField')}>I want to attach an NDA</Label>
                    </Checkbox>
                  </FormGroup>

                  {this.state.ndaField && (
                    <FormGroup>
                      <Upload>
                        <Dropzone
                          accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          maxSize={25000000}
                          multiple={false}
                          className="dropzone"
                          onDrop={this.handleFileUpload}
                        >
                          <img src={ImageUpload} alt="Upload" />
                          {this.state.nda ? this.state.nda.name : 'Upload a file'}
                        </Dropzone>
                      </Upload>
                    </FormGroup>
                  )}

                  <SendButton type="submit" disabled={isSubmitting} sent={this.state.sent}>
                    {isSubmitting ? (
                      <>
                        Sending
                        <Loader src={ImageLoader} alt="Sending" />
                      </>
                    ) : (
                      <>{this.state.sent ? 'Your enquiry has been sent' : 'Get Estimate'}</>
                    )}
                  </SendButton>
                </Form>
              )}
            </Formik>
          </Container>
        </Wrapper>
      </Fragment>
    );
  }
}

export default ContactForm;
