const items = [
  {
    number: '01',
    title: 'Audit',
    description:
      'Our experts provide end-to-end verification of current status and create a roadmap leading directly to desired level of effectiveness. We provide comprehensive revision of methods and technologies, define the project scope, plan the solution functionalities and provide you with the crucial insights regarding the processes, technologies, team structure, management guidelines, methodology of work and all other aspects related to the project.'
  },
  {
    number: '02',
    title: 'Prototyping',
    description:
      'Development of a good product starts with a good prototype. We help to feed the product with audience feedback and move step by step in a lean style towards the success. With the iterative approach we are able to move forward quickly to the defined market fit.'
  },
  {
    number: '03',
    title: 'Backlog refinement',
    description:
      'At the end of each sprint we conduct a collaborative discussion with a product owner to review and discuss all the tasks in the backlog. We share our experience and ideas to suggest improvements. The purpose of backlog refinement is to keep the backlog constantly updated, estimated and properly prioritized.'
  },
  {
    number: '04',
    title: 'Development',
    description:
      'Our developers and project managers pay major focus on quality of the service. Code verification and strict testing standards uncover our core USP- remarkable performance.'
  },
  {
    number: '05',
    title: 'QA',
    description:
      'We offer comprehensive performance, functionality and maintain strict security standards. Our QA engineers go all out to ensure that your code is of the highest quality and maintainable.'
  },
  {
    number: '06',
    title: 'Deployment',
    description:
      'Bringing value to the users reflects our approach. We take the ownership over the whole SDLC from the very beginning continuously. '
  },
  {
    number: '07',
    title: 'INNcare',
    description: 'Project maintenance operations remain guaranteed after the final release'
  }
];

export default items;
