import React from 'react';
import Layout from 'components/shared/Layout';
import Header from 'components/Home/Header';
import Timeline from 'components/Home/Timeline';
import Referals from 'components/Home/Referals';
import CaseStudy from 'components/Home/CaseStudy';
import ContactForm from 'components/Home/ContactForm';

const IndexPage = () => (
  <Layout>
    <Header />
    <Timeline />
    <Referals />
    <CaseStudy />
    <ContactForm />
  </Layout>
);

export default IndexPage;
