import React from 'react';
import Container from 'components/shared/Container';
import { StaticQuery, graphql } from 'gatsby';

import { Wrapper, Title, Card, CardTitle, Description, Image, MobileImage } from './styled';

const CaseStudy = () => (
  <Wrapper>
    <Container>
      <Title>Case Studies</Title>

      <StaticQuery
        query={graphql`
          query CaseStudies {
            healthCare: file(relativePath: { eq: "health-care.png" }) {
              childImageSharp {
                fixed(width: 539, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            healthCareMobile: file(relativePath: { eq: "health-care-mobile.png" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }

            eCommerce: file(relativePath: { eq: "e-commerce.png" }) {
              childImageSharp {
                fixed(width: 700, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            eCommerceMobile: file(relativePath: { eq: "e-commerce-mobile.png" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }

            iot: file(relativePath: { eq: "iot.png" }) {
              childImageSharp {
                fixed(width: 721, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            iotMobile: file(relativePath: { eq: "iot-mobile.png" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <MobileImage fluid={data.healthCareMobile.childImageSharp.fluid} healthCare />

            <Card>
              <CardTitle>Health Care</CardTitle>
              <Description>
                NexCare is a platform, that in conjunction with a modern, two-way voice communication SOS wristband,
                allows caregiver to constantly monitor health, activity and location of a person cared for. In case of a
                threat or inactivity, interface sends real-time signals to the care center or relatives. <br />
                <br />
                NexCare was build using progressive web app techniques. It is integrated with medical devices that
                measure blood sugar, pressure, weight, temperature, oxygen level, heartbeat etc., in order to allow for
                earlier detection and possible prevention of serious health problems.
              </Description>

              <Image fixed={data.healthCare.childImageSharp.fixed} healthCare />
            </Card>

            <MobileImage fluid={data.eCommerceMobile.childImageSharp.fluid} eCommerce />

            <Card invert>
              <CardTitle>E-commerce</CardTitle>
              <Description>
                Luxsfery is a personalized, e-commerce platform revolutionising men’s fashion market. Service connects
                customer with a personal stylist who compiles a box of clothes, to completely free him from the burden
                of shopping. <br />
                <br />
                The key objective was to fully set up, test and build product MVP within tight time frames and set up
                the project for further scaling phases. We put emphasis on fully agile and time to market oriented
                approach. We covered all technology related operations along with full project management.
              </Description>

              <Image fixed={data.eCommerce.childImageSharp.fixed} eCommerce />
            </Card>

            <MobileImage fluid={data.iotMobile.childImageSharp.fluid} iot />
            
            <Card>
              <CardTitle>IoT</CardTitle>
              <Description>
                Using open source components we’ve build a unified Home Automation custom dashboard that integrates
                different 3rd party devices. We’ve created a proxy in Ruby on Rails for gathering and parsing required
                data from OpenHab platform and sending it to custom UI dashboard built in React.
              </Description>

              <Image fixed={data.iot.childImageSharp.fixed} iot />
            </Card>
          </>
        )}
      />
    </Container>
  </Wrapper>
);

export default CaseStudy;
