import styled from 'styled-components';
import { colors } from 'themes';

const BaseStyles = `
  display: inline-block;
  padding: 15px 37px;
  border-radius: 24px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
`;

const BaseLink = styled.a`
  ${BaseStyles};
`;

const BaseButton = styled.button`
  && {
    ${BaseStyles};
  }
`;

export const primaryButton = styled(BaseLink)`
  background-color: ${colors.marineBlue};
  box-shadow: 0 5px 10px -5px rgba(47, 98, 219, 0.5);
  color: white;
`;

export const submitButton = styled(BaseButton).attrs({
  type: 'submit'
})`
  border: 0;
  background-color: ${colors.caribbeanGreen};
  box-shadow: 0 5px 10px -5px #05297e;
`;
